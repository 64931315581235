import { createContext, useReducer, useContext } from "react";
import {
  getSubscriptionsAction,
  getFiltersAction,
  filterProspectsAction,
  showDetailAction,
  getRecentProspectSearchesAction,
  getSearchResultsAction,
} from "../api/common";

const initialState = {
  isLoading: false,
  subscriptions: null,
};

const handlers = {
  SUBSCRIPTIONS: (state, action) => {
    const { subscriptions, isLoading } = action.payload;

    return {
      ...state,
      isLoading,
      subscriptions,
    };
  },
  RECENT_PROSPECT_SEARCHES: (state, action) => {
    const { prospectSearches, isLoading } = action.payload;

    return {
      ...state,
      isLoading,
      prospectSearches,
    };
  },
  SEARCH_RESULTS: (state, action) => {
    const { searchResults, isLoading } = action.payload;

    return {
      ...state,
      isLoading,
      searchResults,
    };
  },
  PROSPECTFILTERS: (state, action) => {
    const { filters, isLoading } = action.payload;

    return {
      ...state,
      isLoading,
      filters,
    };
  },
  PROSPECTS: (state, action) => {
    const { isLoading, prospects, pagination, total } = action.payload;

    return {
      ...state,
      isLoading,
      prospects,
      pagination,
      total,
    };
  },
  CONTACTDETAILSWORK: (state, action) => {
    const { contactDetailsWork } = action.payload;

    return {
      ...state,
      contactDetailsWork
    }
  },
  CONTACTDETAILS: (state, action) => {
    const { isDetailLoading, detailData } = action.payload;
    return {
      ...state,
      prospects: state.prospects.map((obj) => {
        if (detailData?.filter((data) => data.id === obj.id)?.length) {
          let details = detailData?.filter((data) => data.id === obj.id)[0];
          return { ...details };
        }
        return obj;
      }),
      isDetailLoading,
    };
  },
  SELECTED_PROSPECT_FILTERS: (state, action) => {
    const { prospectFilters } = action.payload;

    return {
      ...state,
      prospectFilters,
    };
  },
  TEMPLATE_BODY: (state, action) => {
    const templateBody = action.payload;

    return {
      ...state,
      templateBody,
    };
  },
  COMPANY_INFO: (state, action) => {
    const companyInfoData = action.payload;

    return {
      ...state,
      companyInfoData,
    };
  },
  PRODUCT_INFO: (state, action) => {
    const productInfoData = action.payload;

    return {
      ...state,
      productInfoData,
    };
  },
  BUSINESS_INFO: (state, action) => {
    const businessInfoData = action.payload;

    return {
      ...state,
      businessInfoData,
    };
  },
  ADDITIONAL_INFO: (state, action) => {
    const additionalInfoData = action.payload;

    return {
      ...state,
      additionalInfoData,
    };
  },

  CALLING_PREF_INFO: (state, action) => {
    const callingPrefInfoData = action.payload;

    return {
      ...state,
      callingPrefInfoData,
    };
  },
  COMMUNICATION_INFO: (state, action) => {
    const communicationInfoData = action.payload;

    return {
      ...state,
      communicationInfoData,
    };
  },

  getFilter: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      data,
    };
  },
  RESET_FILTERS: (state) => {
    return {
      ...state,
      filters: null,
      prospectFilters: null,
      prospects: null,
      pagination: null,
      total: null,
      contactDetailsWork: null,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const CommonContext = createContext({
  ...initialState,
  getSubscriptions: () => Promise.resolve(),
});

export const CONTACTDETAILSWORK = (data, dispatch) => {
  try {
    dispatch({
      type: "CONTACTDETAILSWORK",
      payload: {
        contactDetailsWork: data
      }
    })
  }
  catch (err) {
    console.log(err);
    dispatch({
      type: "CONTACTDETAILSWORK",
      contactDetailsWork: null
    })
  }
}

export const getFilter = (data, dispatch) => {
  try {
    dispatch({
      type: "getFilter",
      payload: {
        isLoading: true,
        data
      },
    });

  } catch (err) {
    console.log(err);
    dispatch({
      type: "getFilter",
      payload: {
        isLoading: false,
        subscriptions: null,
      },
    });
  }
};

export const getSubscriptions = async (token, dispatch) => {
  try {
    dispatch({
      type: "SUBSCRIPTIONS",
      payload: {
        isLoading: true,
      },
    });
    const subscriptions = await getSubscriptionsAction(token);
    dispatch({
      type: "SUBSCRIPTIONS",
      payload: {
        isLoading: false,
        subscriptions,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "SUBSCRIPTIONS",
      payload: {
        isLoading: false,
        subscriptions: null,
      },
    });
  }
};

export const getSearchResults = async (search, token, dispatch) => {
  try {
    dispatch({
      type: "SEARCH_RESULTS",
      payload: {
        isLoading: true,
      },
    });
    const searchResults = await getSearchResultsAction(search, token);
    dispatch({
      type: "SEARCH_RESULTS",
      payload: {
        isLoading: false,
        searchResults,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "SEARCH_RESULTS",
      payload: {
        isLoading: false,
        searchResults: null,
      },
    });
  }
};

export const getRecentProspects = async (email, token, dispatch) => {
  try {
    dispatch({
      type: "RECENT_PROSPECT_SEARCHES",
      payload: {
        isLoading: true,
      },
    });
    const prospectSearches = await getRecentProspectSearchesAction(
      email,
      token
    );
    dispatch({
      type: "RECENT_PROSPECT_SEARCHES",
      payload: {
        isLoading: false,
        prospectSearches,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "RECENT_PROSPECT_SEARCHES",
      payload: {
        isLoading: false,
        prospectSearches: null,
      },
    });
  }
};

export const getFilters = async (token, dispatch) => {
  try {
    const filters = await getFiltersAction(token);
    dispatch({
      type: "PROSPECTFILTERS",
      payload: {
        filters,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "PROSPECTFILTERS",
      payload: {
        filters: null,
      },
    });
  }
};

export const selectProspectFilters = (data, dispatch) => {
  dispatch({
    type: "SELECTED_PROSPECT_FILTERS",
    payload: {
      prospectFilters: data,
    },
  });
};
export const addTemplateBody = (data, dispatch) => {
  dispatch({
    type: "TEMPLATE_BODY",
    payload: {
      templateBody: data,
    },
  });
};
export const companyInfoFunc = (data, dispatch) => {
  dispatch({
    type: "COMPANY_INFO",
    payload: data
  });
};
export const productInfoFunc = (data, dispatch) => {
  dispatch({
    type: "PRODUCT_INFO",
    payload: data
  });
};
export const businessInfoFunc = (data, dispatch) => {
  dispatch({
    type: "BUSINESS_INFO",
    payload: data
  });
};
export const additionalInfoFunc = (data, dispatch) => {
  dispatch({
    type: "ADDITIONAL_INFO",
    payload: data
  });
};
export const CallingPrefenceInfoFunc = (data, dispatch) => {
  dispatch({
    type: "CALLING_PREF_INFO",
    payload: data
  });
};
export const CommunicationInfoFunc = (data, dispatch) => {
  dispatch({
    type: "COMMUNICATION_INFO",
    payload: data
  });
};

export const viewEmployeesFilters = (data, prospects, dispatch) => {


  // dispatch({
  //   type: "SELECTED_PROSPECT_FILTERS",
  //   payload: {
  //     prospectFilters: data,
  //   },
  // });
  // dispatch({
  //   type: "PROSPECTS",
  //   payload: {
  //     isLoading: false,
  //     prospects: prospects,
  //     pagination: 1,
  //     total: prospects?.length,
  //   },
  // });
};

export const getProspects = async (data, token, page, dispatch) => {
  try {
    dispatch({
      type: "PROSPECTS",
      payload: {
        isLoading: true,
      },
    });
    dispatch({
      type: "SELECTED_PROSPECT_FILTERS",
      payload: {
        prospectFilters: data,
      },
    });
    const { prospects, pagination, total } = await filterProspectsAction(
      data,
      page,
      token
    );
    dispatch({
      type: "PROSPECTS",
      payload: {
        isLoading: false,
        prospects,
        pagination,
        total,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "PROSPECTS",
      payload: {
        isLoading: false,
        prospects: null,
        pagination: null,
      },
    });
  }
};

export const getContactDetails = async (data, token, dispatch) => {
  try {
    dispatch({
      type: "CONTACTDETAILS",
      payload: {
        isDetailLoading: true,
      },
    });
    const detailData = await showDetailAction(data, token);
    dispatch({
      type: "CONTACTDETAILS",
      payload: {
        isDetailLoading: false,
        detailData,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "CONTACTDETAILS",
      payload: {
        isDetailLoading: false,
      },
    });
  }
};

export const resetFilters = (dispatch) => {
  dispatch({ type: "RESET_FILTERS" });
};

export const CommonProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CommonContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

const useCommonContext = () => useContext(CommonContext);

export default useCommonContext;
