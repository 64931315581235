import { lazy, Suspense } from "react";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import MainLayout from "./components/MainLayout";
import { Class } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import DiscoveryHome from "./DiscoveryPortal/Home";
import ComapnyDetail from "./DiscoveryPortal/CompanyDetail";

// const Loadable = (Component) => (props) =>
// (
//   <Suspense
//     fallback={<CircularProgress
//       className=" m-5  "
//     />}
//   >

//     <Component {...props} />

//   </Suspense>
// );

const Loadable = (Component) => (props) =>
  (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <div style={{
          // .three-quarter-spinner {
          width: " 50px",
          height: "50px",
          border: " 3px solid #6f42c1",
          borderTop: "3px solid transparent",
          borderRadius: "50%",
          animation: "spin .5s linear 0s infinite",
          // }
        }}></div> */}
          <CircularProgress style={{}} />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );

const Landing = Loadable(lazy(() => import("./pages/Landing")));
const ForgotPassword = Loadable(lazy(() => import("./pages/ForgotPassword")));
const Home = Loadable(lazy(() => import("./pages/Home")));
const AccountPage = Loadable(lazy(() => import("./pages/Account Settings")));
const ResetPassword = Loadable(lazy(() => import("./pages/ResetPassword")));
const Prospect = Loadable(lazy(() => import("./pages/Prospect")));
const Notifications = Loadable(lazy(() => import("./pages/Notifications")));
const List = Loadable(lazy(() => import("./pages/List")));
const IntelliConnect = Loadable(lazy(() => import("./pages/IntelliConnect")));



const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/login",
        element: (
          <GuestGuard>
            <Landing />
          </GuestGuard>
        ),
      },
      {
        path: "/forgotpassword",
        element: (
          <GuestGuard>
            <ForgotPassword />
          </GuestGuard>
        ),
      },
      {
        path: "/resetpassword/:token",
        element: (
          <GuestGuard>
            <ResetPassword />
          </GuestGuard>
        ),
      },
      {
        path: "/",
        element: (       
            <DiscoveryHome />
          
        ),
      },
      {
        path: "/discovery/detail/:id",
        element: (
       
            <ComapnyDetail />
       
        ),
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "dashboard",
        element: (
          <AuthGuard>
            <Home />
          </AuthGuard>
        ),
      },
      {
        path: "account",
        element: (
          <AuthGuard>
            <AccountPage />
          </AuthGuard>
        ),
      },
      {
        path: "prospect",
        element: (
          <AuthGuard>
            <Prospect />
          </AuthGuard>
        ),
      },
      {
        path: "list",
        element: (
          <AuthGuard>
            <List />
          </AuthGuard>
        ),
      },
      {
        path: "notifications",
        element: (
          <AuthGuard>
            <Notifications />
          </AuthGuard>
        ),
      },
      {
        path: "intelliConnect",
        element: (
          <AuthGuard>
            <IntelliConnect />
          </AuthGuard>
        ),
      },
    ],
  },
];

export default routes;
