import { toast } from "react-toastify";
import axios from "axios";
import { config } from "../config";

export const forgotPasswordAction = (payload, handleVModal, setIsLoading) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/forget-password/`, payload)
      .then((response) => {
        const { data } = response.data;
        setIsLoading(false);
        handleVModal();
        resolve(data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.email[0]);
        setIsLoading(false);
        reject(
          error?.response?.data.error ||
            error?.response?.data.message ||
            new Error("Internal server error")
        );
      });
  });

export const resetPasswordAction = (
  token,
  new_password,
  setIsLoading,
  navRef
) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/password-reset/confirm/${token}/`, {
        new_password,
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data.code === 200) {
          const { data } = response.data;
          toast.success(response?.data.message);
          navRef.current("/");
          resolve(data);
        } else {
          toast.error(response?.data.message);
          reject(
            response?.data.error ||
              response?.data.message ||
              new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setIsLoading(false);
        reject(
          error?.response?.data.error ||
            error?.response?.data.message ||
            new Error("Internal server error")
        );
      });
  });

export const changePasswordAction = (
  token,
  data,
  setPopupModal,
  setIsLoading
) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${config.apiUrl}/change-password/`, data, {
        headers: { Authorization: `Token ${token}` },
      })

      .then((response) => {
        if (response.data.code === 200) {
          const { data } = response.data;
          toast.success(response?.data.message);
          setPopupModal(false);
          resolve(data);
        } else {
          setIsLoading(false);
          toast.error(response?.data.message);
          reject(
            response?.data.error ||
              response?.data.message ||
              new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something went wrong");
        reject(
          error?.response?.data.error ||
            error?.response?.data.message ||
            new Error("Internal server error")
        );
      });
  });

export const updateProfileAction = (payload, email, token) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${config.apiUrl}/update-user/${email}/`, payload, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data[0];
          toast.success("Profile updated Successfully");
          resolve(data);
        } else {
          toast.error(response?.data.message);
          reject(
            response?.data.error ||
              response?.data.message ||
              new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        reject(
          error?.response?.data.error ||
            error?.response?.data.message ||
            new Error("Internal server error")
        );
      });
  });

export const getProfileAction = (email, token) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${config.apiUrl}/email-user/`,
        { email },
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data[0];
          resolve(data);
        } else {
          reject(
            response?.data.error ||
              response?.data.message ||
              new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
            error?.response?.data.message ||
            new Error("Internal server error")
        );
      });
  });

  export const paymentAction = (navRef, token , payload , setIsLoading) =>
  new Promise((resolve, reject) => {
    axios
      (
        {
         method : "POST" ,
         url : `${config.apiUrl}/stripe/` ,
         data : payload ,
         headers: { Authorization: `Token ${token}` }
        }
        )
      .then((response) => {
        if (response.data.code === 200) {
          const data = response?.data?.data;
          toast.success("Payment Done Successfully");
          setIsLoading(false);
          navRef.current("/");
          resolve(data);
        }
        else if(response.data.code === 202){
          const data = response?.data?.code;
          setIsLoading(false);
          resolve(data);
        }
        else {
          setIsLoading(false);
          toast.error(response?.data.message);
          reject(
            response?.data.error ||
              response?.data.message ||
              new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something went wrong");
        reject(
          error?.response?.data.error ||
            error?.response?.data.message ||
            new Error("Internal server error")
        );
      });
  });
