import axios from "axios";
import { toast } from "react-toastify";
import { config } from "../config";

export const loginAction = (payload) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/api-user-login/`, payload)
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data[0];
          toast.success("Logged In Successfully");
          resolve(data);
        } else {
          toast.error(response?.data.message || "Something went wrong");
          reject(
            response?.data.error ||
              response?.data.message ||
              new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        reject(
          error?.response?.data.error ||
            error?.response?.data.message ||
            new Error("Internal server error")
        );
      });
  });

export const socialLoginAction = (payload) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/google/`, payload)
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data[0];
          toast.success("Logged In Successfully");
          resolve(data);
        } else {
          toast.error(response?.data.message || "Something went wrong");
          reject(
            response?.data.error ||
              response?.data.message ||
              new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        reject(
          error?.response?.data.error ||
            error?.response?.data.message ||
            new Error("Internal server error")
        );
      });
  });
