import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { microsoftLogin } from "../contexts/JWTContext";
import useAuth from "../hooks/useAuth";

const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestLocation] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const username = queryParams.get("username");
  const full_name = queryParams.get("full_name");
  const first_name = queryParams.get("first_name");
  const email = queryParams.get("email");
  const staff_permission = queryParams.get("staff_permission");

  if (token) {
    microsoftLogin(
      {
        token,
        username,
        full_name,
        first_name,
        email,
        staff_permission:
          staff_permission === "true" || staff_permission === "True"
            ? true
            : false,
      },
      auth.jwtDispatch
    );
  }

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestLocation(location.pathname);
    }
    return <Navigate to="/login" />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return children;
};

export default AuthGuard;
