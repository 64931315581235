import { createContext, useReducer } from "react";
import { updateProfileAction, getProfileAction } from "../api/user";

const initialState = {
  isLoading: false,
  userData: null,
};

const handlers = {
  USER: (state, action) => {
    const { userData, isLoading } = action.payload;

    return {
      ...state,
      isLoading,
      userData,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const UserContext = createContext({
  ...initialState,
  updateProfile: () => Promise.resolve(),
  getProfile: () => Promise.resolve(),
});

export const updateProfile = async (data, email, token, dispatch) => {
  try {
    dispatch({
      type: "USER",
      payload: {
        isLoading: true,
      },
    });
    const user = await updateProfileAction(data, email, token);
    const cUser = JSON.parse(localStorage.getItem("cadena_user"));
    localStorage.setItem("cadena_user", JSON.stringify({ ...cUser, ...user }));
    dispatch({
      type: "USER",
      payload: {
        isLoading: false,
        userData: { ...cUser, ...user },
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "USER",
      payload: {
        isLoading: false,
        userData: null,
      },
    });
  }
};

export const getProfile = async (email, token, dispatch) => {
  try {
    dispatch({
      type: "USER",
      payload: {
        isLoading: true,
      },
    });
    const user = await getProfileAction(email, token);
    dispatch({
      type: "USER",
      payload: {
        isLoading: false,
        userData: user,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: "USER",
      payload: {
        isLoading: false,
        userData: null,
      },
    });
  }
};

export const UserProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider
      value={{
        ...state,
        dispatcher: dispatch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
